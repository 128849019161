import React, { FunctionComponent, useLayoutEffect } from "react";

import { AuthServiceSingleton } from "./authService";

export const AuthLogout: FunctionComponent = ({ children }) => {
  useLayoutEffect(() => {
    AuthServiceSingleton.get().signoutRedirect().then();
  }, []);

  return <>{children}</>;
};
