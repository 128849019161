import { makeStyles } from "@material-ui/core";

export const menuStyles = makeStyles((theme) => ({
  drawer: {
    height: "100vh",
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: 200,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  paper: {
    position: "relative",
    overflowX: "hidden",
  },
  menuSpacer: {
    flexGrow: 1,
  },
  logoImg: {
    padding: "20px 20px",
  },
  logoPictoImg: {
    padding: "20px 8px",
    width: theme.spacing(7),
  },
  menuTitle: {
    color: theme.palette.primary.main,
  },
  subMenuIcon: {
    color: theme.palette.secondary.main,
  },
  subMenuText: {
    color: "#000000",
  },
}));
