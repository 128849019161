import React, { FunctionComponent } from "react";
import { Backdrop, Box, CircularProgress, Typography } from "@material-ui/core";
import { styled, withTheme } from "@material-ui/core/styles";

const Progress = styled(withTheme(CircularProgress))(({ theme }) => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: theme.spacing(3),
  color: theme.palette.primary.main,
}));

export const ProgressMsg: FunctionComponent = ({ children }) => {
  return (
    <Backdrop open={true}>
      <Box display="block">
        <Typography variant="h3">{children}</Typography>
        <Progress />
      </Box>
    </Backdrop>
  );
};
