import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import orgsSlice from "../features/orgs/orgsSlice";
import uiSlice from "../features/ui/uiSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    orgs: orgsSlice,
    ui: uiSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
