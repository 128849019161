import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import { store } from "./app/store";
import oidcSettings from "./oidcSettings";
import { App } from "./components/app";
import { AuthProvider } from "./features/auth";
import { ProgressMsg } from "./components/progressMsg";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AuthProvider
          oidcSettings={oidcSettings}
          logoutRedirect="https://neovops.io"
          logoutLocation="/auth/logout"
          progressAuthComponent={<ProgressMsg>Authentication...</ProgressMsg>}
          progressLogoutComponent={<ProgressMsg>Logout...</ProgressMsg>}
        >
          <App />
        </AuthProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
