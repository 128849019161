import React, { FunctionComponent, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthServiceSingleton } from "./authService";

export const AuthCallback: FunctionComponent = ({ children }) => {
  const [redirectLocation, setRedirectLocation] = useState("");

  useEffect(() => {
    AuthServiceSingleton.get()
      .signinCallback()
      .then((location) => {
        setRedirectLocation(location);
      });
  }, [setRedirectLocation]);

  if (redirectLocation) {
    return <Redirect to={redirectLocation} />;
  }
  return <>{children}</>;
};
