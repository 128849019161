import React from "react";
import { useSelector } from "react-redux";
import { selectProfile } from "../auth/authSlice";

export function Hello() {
  const profile = useSelector(selectProfile);

  return (
    <div>
      <h1>Hello {profile.given_name}!</h1>
    </div>
  );
}
