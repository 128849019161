import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface UIState {
  menuOpen: boolean;
}

const initialState: UIState = {
  menuOpen: true,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openMenu: (state) => {
      state.menuOpen = true;
    },
    closeMenu: (state) => {
      state.menuOpen = false;
    },
    toggleMenu: (state) => {
      state.menuOpen = !state.menuOpen;
    },
  },
});

export const { openMenu, closeMenu, toggleMenu } = uiSlice.actions;

export const selectMenuOpen = (state: RootState) => state.ui.menuOpen;

export default uiSlice.reducer;
