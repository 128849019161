import React, { ReactElement } from "react";
import { menuStyles } from "./menuStyle";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectMenuOpen } from "../../features/ui/uiSlice";
import { Link } from "react-router-dom";

interface SubMenuItemProps {
  text: string;
  icon: ReactElement;
  link: string;
}

export const SubMenuItem = ({ text, icon, link }: SubMenuItemProps) => {
  const classes = menuStyles();
  const isMenuOpen = useSelector(selectMenuOpen);

  return (
    <ListItem
      button
      component={Link}
      to={link}
      key={text}
      className={classes.subMenuText}
    >
      <ListItemIcon className={classes.subMenuIcon}>
        {isMenuOpen ? (
          icon
        ) : (
          <Tooltip title={text} placement="right">
            {icon}
          </Tooltip>
        )}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};
