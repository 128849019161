import { User, UserManager, UserManagerSettings } from "oidc-client";
import { Location } from "history";
import { store } from "../../app/store";
import { removeUser, setUser } from "./authSlice";

export class AuthService extends UserManager {
  callbackPath: string;
  logoutCallbackPath: string;

  constructor(oidcSettings: UserManagerSettings) {
    super(oidcSettings);
    this.events.addUserLoaded(this.setUserState);
    this.events.addUserUnloaded(this.removeUserState);
    this.events.addAccessTokenExpiring(() => this.signinSilent());
    this.events.addAccessTokenExpired(() => this.removeUserState());

    this.callbackPath = new URL(String(this.settings.redirect_uri)).pathname;
    this.logoutCallbackPath = new URL(
      String(this.settings.post_logout_redirect_uri)
    ).pathname;
  }

  signinRedirect = (location: Location) => {
    return super.signinRedirect({ data: { location: location } });
  };

  signinCallback = () => {
    return super.signinCallback().then((oidcUser) => {
      return oidcUser.state["location"];
    });
  };

  loadUserState = () => {
    return this.getUser().then(this.updateUserState);
  };

  updateUserState = (oidcUser: User | null) => {
    return oidcUser ? this.setUserState(oidcUser) : this.removeUserState();
  };

  setUserState = (oidcUser: User) => {
    store.dispatch(
      setUser({
        profile: oidcUser.profile,
        idToken: oidcUser.id_token,
      })
    );
    return oidcUser;
  };

  removeUserState = () => {
    store.dispatch(removeUser());
    return null;
  };
}

export class AuthServiceSingleton {
  static authServer: AuthService;

  static create = (oidcSettings: UserManagerSettings) => {
    AuthServiceSingleton.authServer = new AuthService(oidcSettings);
    return AuthServiceSingleton.authServer;
  };

  static get = () => {
    if (!AuthServiceSingleton.authServer) {
      throw Error("AuthService must be created before.");
    }
    return AuthServiceSingleton.authServer;
  };
}
