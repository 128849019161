import React, { FunctionComponent, useState } from "react";
import { List, ListItem, ListItemText, Tooltip } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { ListItemIcon } from "@material-ui/core";
import { menuStyles } from "./menuStyle";
import { useSelector } from "react-redux";
import { selectMenuOpen } from "../../features/ui/uiSlice";

interface SubMenuProps {
  open: boolean;
  title: string;
}

export const SubMenu: FunctionComponent<SubMenuProps> = ({
  title,
  open,
  children,
}) => {
  const isMenuOpen = useSelector(selectMenuOpen);
  const [isOpen, setIsOpen] = useState(open);
  const classes = menuStyles();

  const arrow = (isOpen: boolean) => {
    return isOpen ? (
      <ArrowDropDownIcon color="primary" />
    ) : (
      <ArrowRightIcon color="primary" />
    );
  };

  return (
    <>
      <List>
        <ListItem button key={title} onClick={() => setIsOpen(!isOpen)}>
          <ListItemIcon>
            {isMenuOpen ? (
              arrow(isOpen)
            ) : (
              <Tooltip title={title} placement="right">
                {arrow(isOpen)}
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={title} className={classes.menuTitle} />
        </ListItem>
        {isOpen ? children : null}
      </List>
    </>
  );
};
