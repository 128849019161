import React, { FunctionComponent } from "react";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import BuildIcon from "@material-ui/icons/Build";
import { SubMenu } from "./SubMenu";
import { SubMenuItem } from "./SubMenuItem";

export const MenuContent: FunctionComponent = () => {
  return (
    <>
      <SubMenu title="General" open>
        <SubMenuItem text="Profile" icon={<PersonIcon />} link="/profile" />
        <SubMenuItem text="Organizations" icon={<PeopleIcon />} link="/orgs" />
      </SubMenu>
      <SubMenu title="Builder" open>
        <SubMenuItem
          text="Secrets"
          icon={<VpnKeyIcon />}
          link="/builder/secrets"
        />
        <SubMenuItem
          text="Builds"
          icon={<BuildIcon />}
          link="/builder/builds"
        />
      </SubMenu>
    </>
  );
};
