import axios from "axios";

import { store } from "../app/store";
import { selectIdToken } from "../features/auth/authSlice";

export const apiWrapper = (service: string) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${selectIdToken(store.getState())}`,
    },
    baseURL: `${getApiURL()}/api/v1/${service}`,
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        console.error(
          "Authentication error with API, you should try to reload page."
        );
      }
      return error;
    }
  );
  return instance;
};

const getApiURL = () => `${window.location.protocol}//${getApiHost()}`;

const getApiHost = () =>
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
    ? window.location.host
    : window.location.host.replace("console", "api");
