import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { refreshOrgs, selectOrgs, Org } from "./orgsSlice";
import { PageLoading } from "../../components/pageLoading";

const OrgsList = (orgs: Org[]) => {
  return (
    <ul>
      {orgs.map((org, index) => (
        <li key={index}>{org.name}</li>
      ))}
    </ul>
  );
};

export const Orgs = () => {
  const orgs = useSelector(selectOrgs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshOrgs({ force: false }));
  }, [dispatch]);

  return (
    <div>
      <h1>My Organizations</h1>
      {orgs === null ? <PageLoading /> : OrgsList(orgs)}
    </div>
  );
};
