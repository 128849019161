import React, { useEffect } from "react";
import { selectUser } from "./authSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AuthServiceSingleton } from "./authService";

interface AuthNeededProps {
  progressComponent: React.ReactNode;
  children: React.ReactNode;
}

export const AuthNeeded = ({
  progressComponent,
  children,
}: AuthNeededProps) => {
  const user = useSelector(selectUser);
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      AuthServiceSingleton.get().signinRedirect(location).then(null);
    }
  }, [user, location]);

  return user ? <>{children}</> : <>{progressComponent}</>;
};
