import React, { useEffect, useMemo, useState } from "react";
import { AuthCallback } from "./AuthCallback";
import { AuthLogoutCallback } from "./AuthLogoutCallback";
import { AuthLogout } from "./AuthLogout";
import { Route, Switch } from "react-router-dom";
import { AuthServiceSingleton } from "./authService";
import { UserManagerSettings } from "oidc-client";

interface AuthProviderProps {
  oidcSettings: UserManagerSettings;
  logoutRedirect: string;
  logoutLocation: string;
  progressAuthComponent: React.ReactNode;
  progressLogoutComponent: React.ReactNode;
  children: React.ReactNode;
}

export const AuthProvider = ({
  oidcSettings,
  logoutRedirect,
  logoutLocation,
  progressAuthComponent,
  progressLogoutComponent,
  children,
}: AuthProviderProps) => {
  const authService = useMemo(() => AuthServiceSingleton.create(oidcSettings), [
    oidcSettings,
  ]);
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    authService.loadUserState().then(() => {
      setUserLoaded(true);
    });
  }, [authService]);

  if (!userLoaded) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={authService.callbackPath}>
        <AuthCallback>{progressAuthComponent}</AuthCallback>
      </Route>
      <Route exact path={authService.logoutCallbackPath}>
        <AuthLogoutCallback logoutRedirect={logoutRedirect} />
      </Route>
      <Route exact path={logoutLocation}>
        <AuthLogout>{progressLogoutComponent}</AuthLogout>
      </Route>
      <Route>{children}</Route>
    </Switch>
  );
};
