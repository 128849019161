import { Switch, Route } from "react-router-dom";

import { Hello } from "../../features/hello";
import { Orgs } from "../../features/orgs";

export const AppRouter = () => {
  return (
    <Switch>
      <Route path="/orgs">
        <Orgs />
      </Route>
      <Route path="/">
        <Hello />
      </Route>
    </Switch>
  );
};
