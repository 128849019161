import React, { FunctionComponent } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProfile } from "../../features/auth/authSlice";
import { ProfileAvatar } from "./ProfileAvatar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import headerBarStyles from "./headerBarStyle";
import { People } from "@material-ui/icons";

export const ProfileCard: FunctionComponent = () => {
  const profile = useSelector(selectProfile);
  const classes = headerBarStyles();

  const fullName = `${profile.given_name} ${profile.family_name}`;

  return (
    <Card>
      <CardHeader avatar={<ProfileAvatar />} title={fullName} />
      <CardContent className={classes.profileCardContent}>
        <Divider />
        <List>
          <ListItem component={Link} to="/profile" button>
            <ListItemIcon>
              <People className={classes.buttonListIcon} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem component={Link} to="/auth/logout" button>
            <ListItemIcon>
              <ExitToAppIcon className={classes.buttonListIcon} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};
