import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { apiWrapper } from "../../utils/apiWrapper";

export interface Org {
  name: string;
}

interface OrgsState {
  orgs: Org[] | null;
}

const initialState: OrgsState = {
  orgs: null,
};

export const refreshOrgs = createAsyncThunk<
  Org[] | null,
  {
    force: boolean;
  },
  {
    state: RootState;
  }
>("orgs/refreshOrgs", async ({ force = false }, { getState }) => {
  const { orgs } = getState().orgs;

  if (force || orgs === null) {
    return (await apiWrapper("orgs").get("/")).data as Org[];
  }
  return null;
});

export const orgsSlice = createSlice({
  name: "orgs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshOrgs.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.orgs = payload;
      }
    });
  },
});

export const selectOrgs = (state: RootState) => state.orgs.orgs;

export default orgsSlice.reducer;
