import { makeStyles } from "@material-ui/core";

const headerBarStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  profileCardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  buttonListIcon: {
    color: theme.palette.secondary.main,
  },
}));

export default headerBarStyles;
