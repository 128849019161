import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { AuthNeeded } from "../../features/auth";
import { neovopsTheme } from "../../themes";
import { ProgressMsg } from "../progressMsg";
import { HeaderBar, HeaderBarSpacer } from "../headerBar";
import { appStyles } from "./appStyle";
import { Menu } from "../menu";
import { AppRouter } from "./AppRouter";

export const App = () => {
  const classes = appStyles();

  return (
    <ThemeProvider theme={neovopsTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <AuthNeeded
          progressComponent={<ProgressMsg>Authentication ...</ProgressMsg>}
        >
          <HeaderBar />
          <Menu />
          <main className={classes.main}>
            <HeaderBarSpacer />
            <Container className={classes.content} maxWidth="lg">
              <AppRouter />
            </Container>
          </main>
        </AuthNeeded>
      </div>
    </ThemeProvider>
  );
};
