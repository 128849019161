import React, { FunctionComponent } from "react";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectProfile } from "../../features/auth/authSlice";
import headerBarStyles from "./headerBarStyle";

export const ProfileAvatar: FunctionComponent = () => {
  const classes = headerBarStyles();
  const profile = useSelector(selectProfile);

  const firstLetter = profile.given_name ? profile.given_name[0] : "?";

  return <Avatar className={classes.avatar}>{firstLetter}</Avatar>;
};
