import { FunctionComponent, useEffect } from "react";
import { AuthServiceSingleton } from "./authService";

interface AuthLogoutCallbackProps {
  logoutRedirect: string;
}

export const AuthLogoutCallback: FunctionComponent<AuthLogoutCallbackProps> = ({
  logoutRedirect,
}) => {
  useEffect(() => {
    AuthServiceSingleton.get()
      .signoutRedirectCallback()
      .then(() => {
        window.location.replace(logoutRedirect);
      });
  }, [logoutRedirect]);

  return null;
};
