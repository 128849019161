import React, { FunctionComponent } from "react";
import {
  AppBar,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch } from "react-redux";
import { toggleMenu } from "../../features/ui/uiSlice";
import { ProfileAvatar } from "./ProfileAvatar";
import { ProfileCard } from "./ProfileCard";
import headerBarStyles from "./headerBarStyle";

export const HeaderBar: FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = headerBarStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="secondary"
          aria-label="open drawer"
          onClick={() => dispatch(toggleMenu())}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          className={classes.title}
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Console
        </Typography>
        <IconButton color="secondary" onClick={handleClick}>
          <ProfileAvatar />
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ProfileCard />
        </Popover>
      </Toolbar>
    </AppBar>
  );
};
