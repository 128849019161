import { UserManagerSettings } from "oidc-client";

const clientID = "2n1cg8lc53vgongagr1nhdhlja";
const baseAuthEndpoint = "https://auth.neovops.io";
const baseCognitoEndpoint =
  "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_NZarpGJYN";

const baseURI = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;
const logoutCallbackURI = `${baseURI}/auth/logout_callback`;

const oidcSettings: UserManagerSettings = {
  client_id: clientID,
  redirect_uri: `${baseURI}/auth/callback`,
  response_type: "code",
  scope: "openid profile email",
  authority: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_NZarpGJYN",
  silent_redirect_uri: `${baseURI}/auth/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  post_logout_redirect_uri: logoutCallbackURI,
  metadata: {
    issuer: baseCognitoEndpoint,
    authorization_endpoint: `${baseAuthEndpoint}/oauth2/authorize`,
    token_endpoint: `${baseAuthEndpoint}/oauth2/token`,
    userinfo_endpoint: `${baseAuthEndpoint}/oauth2/userInfo`,
    end_session_endpoint: `${baseAuthEndpoint}/logout?logout_uri=${logoutCallbackURI}&client_id=${clientID}&`,
    jwks_uri: `${baseCognitoEndpoint}/.well-known/jwks.json`,
  },
};

export default oidcSettings;
