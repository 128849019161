import { makeStyles } from "@material-ui/core";

export const appStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: "flex",
  },
  main: {
    width: "100%",
  },
  content: {
    flexGrow: 1,
  },
}));
