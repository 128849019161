import { Profile } from "oidc-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface UserState {
  profile: Profile;
  idToken: string;
}

interface AuthState {
  user: UserState | null;
}

const initialState: AuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, removeUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export const selectProfile = (state: RootState) => {
  if (!state.auth.user) {
    throw Error("User not loaded");
  }
  return state.auth.user.profile;
};

export const selectIdToken = (state: RootState) => {
  if (!state.auth.user) {
    throw Error("User not loaded");
  }
  return state.auth.user.idToken;
};

export default authSlice.reducer;
