import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { Drawer } from "@material-ui/core";
import { menuStyles } from "./menuStyle";
import { HeaderBarSpacer } from "../headerBar";
import { useSelector } from "react-redux";
import { selectMenuOpen } from "../../features/ui/uiSlice";
import { MenuContent } from "./MenuContent";

export const Menu: FunctionComponent = () => {
  const classes = menuStyles();
  const isMenuOpen = useSelector(selectMenuOpen);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isMenuOpen,
        [classes.drawerClose]: !isMenuOpen,
      })}
      classes={{
        paper: classes.paper,
      }}
      elevation={3}
      open={isMenuOpen}
    >
      <HeaderBarSpacer />
      <MenuContent />
      <div className={classes.menuSpacer} />
      {isMenuOpen ? (
        <img className={classes.logoImg} alt="Logo" src="/neovops.svg" />
      ) : (
        <img
          className={classes.logoPictoImg}
          alt="Logo"
          src="/neovops-picto.svg"
        />
      )}
    </Drawer>
  );
};
