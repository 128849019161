import { CircularProgress } from "@material-ui/core";
import { styled, withTheme } from "@material-ui/core/styles";

export const PageLoading = styled(withTheme(CircularProgress))(({ theme }) => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: theme.spacing(5),
  color: theme.palette.primary.main,
}));
