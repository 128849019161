import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

export const neovopsTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#083c64",
      },
      secondary: {
        main: "#f88c52",
      },
      background: {
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })
);
